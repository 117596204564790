import "./App.css";
import logo from "./images/Logo White.png";
function App() {
  return (
    <div className="App">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="coming-soon-container">
        <h1>Lernerra Coming </h1>
        <h1>Soon!</h1>
        <p>Our website is coming soon! Stay tuned for updates.</p>
        <div className="progress-bar-container">
          <div className="progress-bar"></div>
        </div>
        <div>
          <a
            href="https://www.strategyzinc.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="link-button">Discover more</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
